<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 27" preserveAspectRatio="xMinYMin meet" stroke="#004782">
    <path d="M14.692 4.25l9.193 9.192m0 0l-9.193 9.193m9.193-9.193H1" stroke-width="2"/>
  </svg>
</template>

<script>
export default {
  name: 'ArrowSVG'
}
</script>