<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
    <path stroke="#681D24" stroke-width="2" d="M20 10H0M10 20V0"/>
  </svg>
</template>

<script>
export default {
  name: 'ExpandSVG'
}
</script>