<template>
  <div class="c-features" id="features">
    <div class="c-features__content c-section__content" data-section-content>
      <SectionLabel title="the features" background="wine" text="plinth" class="c-features__section-label" data-animate/>
      <div class="c-features__headline" data-animate>an elevated way of living</div>
      <div class="c-features__section c-features__section--alt" data-animate>
        <div class="c-features__unit-details-container">
          <h2 class="c-features__section-title">units</h2>
          <div class="c-features__units-container">
            <UnitAccordion 
              title="Loft" 
              size="550 SF" 
              price="$1,799" 
              button="View Photos"
            >
                <swiper 
                :pagination='{el: ".c-carousel__gallery-pagination", "type": "fraction"}'
                :navigation='{nextEl: ".c-carousel__gallery-next"}'
                :loop="true"
                :spaceBetween="0" 
                :slidesPerView="1"
                class="c-carousel">
                  <swiper-slide>
                    <div class="c-carousel__slide">
                      <img class="c-carousel__slide-image" src="/images/loft/loft-01.jpg" alt="bedroom"/>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="c-carousel__slide">
                      <img class="c-carousel__slide-image" src="/images/loft/loft-02.jpg" alt="living room area"/>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="c-carousel__slide">
                      <img class="c-carousel__slide-image" src="/images/loft/loft-03.jpg" alt="living room area"/>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="c-carousel__slide">
                      <img class="c-carousel__slide-image" src="/images/loft/loft-04.jpg" alt="kitchen"/>
                    </div>
                  </swiper-slide>
                  <div class="c-carousel__gallery-controls">
                    <div class="c-carousel__gallery-control c-carousel__gallery-pagination"></div>
                    <div class="c-carousel__gallery-control c-carousel__gallery-next">
                      <NextSVG />
                    </div>
                  </div>
                </swiper>
            </UnitAccordion>
            <UnitAccordion 
              title="1 Bedroom" 
              size="500-745 SF" 
              price="$1,680-$1,880" 
              button="View Photos"
            >
                <swiper 
                :pagination='{el: ".c-carousel__gallery-pagination", "type": "fraction"}'
                :navigation='{nextEl: ".c-carousel__gallery-next"}'
                :loop="true"
                :spaceBetween="0" 
                :slidesPerView="1"
                class="c-carousel">
                  <swiper-slide>
                    <div class="c-carousel__slide">
                      <img class="c-carousel__slide-image" src="/images/1-bedroom/1-bedroom-01.jpg" alt="bedroom and kitchen"/>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="c-carousel__slide">
                      <img class="c-carousel__slide-image" src="/images/1-bedroom/1-bedroom-02.jpg" alt="living room"/>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="c-carousel__slide">
                      <img class="c-carousel__slide-image" src="/images/1-bedroom/1-bedroom-03.jpg" alt="living room and kitchen"/>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="c-carousel__slide">
                      <img class="c-carousel__slide-image" src="/images/1-bedroom/1-bedroom-04.jpg" alt="bedroom"/>
                    </div>
                  </swiper-slide>
                  <div class="c-carousel__gallery-controls">
                    <div class="c-carousel__gallery-control c-carousel__gallery-pagination"></div>
                    <div class="c-carousel__gallery-control c-carousel__gallery-next">
                      <NextSVG />
                    </div>
                  </div>
                </swiper>
            </UnitAccordion>
            <UnitAccordion 
              title="2 Bedroom" 
              size="935-1,145 SF" 
              price="$2,300-$2,915" 
              button="View Photos"
            >
                <swiper 
                :pagination='{el: ".c-carousel__gallery-pagination", "type": "fraction"}'
                :navigation='{nextEl: ".c-carousel__gallery-next"}'
                :loop="true"
                :spaceBetween="0" 
                :slidesPerView="1"
                class="c-carousel">
                  <swiper-slide>
                    <div class="c-carousel__slide">
                      <img class="c-carousel__slide-image" src="/images/2-bedroom/2-bedroom-01.jpg" alt="kitchen"/>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="c-carousel__slide">
                      <img class="c-carousel__slide-image" src="/images/2-bedroom/2-bedroom-02.jpg" alt="bathroom"/>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="c-carousel__slide">
                      <img class="c-carousel__slide-image" src="/images/2-bedroom/2-bedroom-03.jpg" alt="bedroom 1"/>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="c-carousel__slide">
                      <img class="c-carousel__slide-image" src="/images/2-bedroom/2-bedroom-04.jpg" alt="bedroom 2"/>
                    </div>
                  </swiper-slide>
                  <div class="c-carousel__gallery-controls">
                    <div class="c-carousel__gallery-control c-carousel__gallery-pagination"></div>
                    <div class="c-carousel__gallery-control c-carousel__gallery-next">
                      <NextSVG />
                    </div>
                  </div>
                </swiper>
            </UnitAccordion>
          </div>
          <div class="c-features__list-container">
            <div class="c-features__list">
            <h3 class="c-features__list-title">Features</h3>
            <ul class="c-features__list-items">
              <li>
                <span class="c-features__sublist-title">Unique Unit Layouts</span>
                <ul>
                  <li>Bi-Levels</li>
                  <li>Private Roof Decks</li>
                  <li>Street-Facing Live/Work Lofts</li>
                </ul>
              </li>
              <li>Modern & Functional Kitchen</li>
              <li>Natural, Sustainable Materials Of The Highest Quality</li>
            </ul>
          </div>
          <div class="c-features__list">
            <h3 class="c-features__list-title">Finishes</h3>
            <ul class="c-features__list-items">
              <li>Modern Stainless Steel Appliances</li>
              <li>Contemporary Two-Tone Kitchen Cabinets</li>
              <li>Fresh, Clean White Solid Surface Countertops</li>
              <li>Textured White Backsplash</li>
              <li>Hickory Wood Flooring</li>
              <li>Stone Texture Tile In Bathrooms</li>
              <li>Sleek Black Fitures In Bathroom</li>
            </ul>
          </div>
        </div>
        </div>
        
      </div>
      <div class="c-features__section" data-animate>
        <div class="c-features__unit-details-container">
          <h2 class="c-features__section-title">the deck</h2>
          <h3 class="c-features__section-info">7,200 sq. ft roof deck</h3>
          <div data-animate>
            <swiper 
            :pagination='{el: ".c-carousel__gallery-pagination", "type": "fraction"}'
            :navigation='{nextEl: ".c-carousel__gallery-next"}'
            :loop="true" 
            :spaceBetween="0" 
            :slidesPerView="1"
            class="c-carousel">
              <swiper-slide>
                <div class="c-carousel__slide">
                  <img class="c-carousel__slide-image" src="/images/deck/deck-01.jpg" alt=""/>
                </div>
              </swiper-slide>
              <div class="c-carousel__gallery-controls">
                <div class="c-carousel__gallery-control c-carousel__gallery-pagination"></div>
                <div class="c-carousel__gallery-control c-carousel__gallery-next">
                  <NextSVG />
                </div>
              </div>
            </swiper>
          </div>
          <div class="c-features__list-container">
          <div class="c-features__list">
            <h3 class="c-features__list-title">Features</h3>
            <ul class="c-features__list-items">
              <li>Vibrant Social Spaces</li>
              <li>Brightly Colored Flexible Seating For Gatherings & Activities</li>
              <li>Gravel Gardens With Cozy Fire Pits</li>
              <li>Lush Raised Planting Beds</li>
              <li>Expansive Views Of Center City Skyline</li>
              <li>Dog Park With Outdoor Shower</li>
            </ul>
          </div>
          <div class="c-features__list">
            <h3 class="c-features__list-title">Finishes</h3>
            <ul class="c-features__list-items">
              <li>Stone Texture Tile Promenade</li>
              <li>Naturally Weathering Wood Decks</li>
            </ul>
          </div>
          </div>
        </div>
      </div>
      <div class="c-features__section c-features__section--alt" data-animate>
        <div class="c-features__unit-details-container">
          <h2 class="c-features__section-title">The Commons</h2>
          <div class="c-features__details">
            <!-- <div>
              <h3 class="c-features__details-title">The Lobby</h3>
              <div class="c-features__unit-details-info">
                <span>0000 SF</span>
              </div>
            </div>
            <div>
              <h3 class="c-features__details-title">The Lounge</h3>
              <div class="c-features__unit-details-info">
                <span>0000 SF</span>
              </div>
            </div>
          </div>
          <div data-animate>
            <swiper 
            :pagination='{el: ".c-carousel__gallery-pagination", "type": "fraction"}'
            :navigation='{nextEl: ".c-carousel__gallery-next"}'
            :loop="true" 
            :spaceBetween="0" 
            :slidesPerView="1"
            class="c-carousel">
              <swiper-slide>
                <div class="c-carousel__slide">
                  <img class="c-carousel__slide-image" src="/images/gallery-1.jpg" alt=""/>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="c-carousel__slide">
                  <img class="c-carousel__slide-image" src="/images/gallery-2.jpg" alt=""/>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="c-carousel__slide">
                  <img class="c-carousel__slide-image" src="/images/gallery-3.jpg" alt=""/>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="c-carousel__slide">
                  <img class="c-carousel__slide-image" src="/images/gallery-4.jpg" alt=""/>
                </div>
              </swiper-slide>
              <div class="c-carousel__gallery-controls">
                <div class="c-carousel__gallery-control c-carousel__gallery-pagination"></div>
                <div class="c-carousel__gallery-control c-carousel__gallery-next">
                  <NextSVG />
                </div>
              </div>
            </swiper> -->
          </div>
          <div class="c-features__list-container c-features__list-container--last">
            <div class="c-features__list">
              <h3 class="c-features__list-title">Features</h3>
              <ul class="c-features__list-items">
                <li>Warm & Inviting Front Lobby</li>
                <li>Communal Fireplace</li>
                <li>Cozy Lounge For Late Night Conversations</li>
              </ul>
            </div>
            <div class="c-features__list">
              <h3 class="c-features__list-title">Finishes</h3>
              <ul class="c-features__list-items">
                <li>Textural Stone Tile Flooring</li>
                <li>Color Washed Plywood Panel Feature Wall</li>
                <li>Artistic Carpet Movement</li>
                <li>Hand Crafted White Subway Tile Backsplash</li>
                <li>Modern White Cabinetry</li>
                <li>Warm Plywood Fireplace Surround With Modern Metal Accent Panel</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NextSVG from './NextSVG.vue';
import SectionLabel from './SectionLabel.vue';
import UnitAccordion from './UnitAccordion.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/swiper.scss';
import "swiper/components/navigation/navigation.min.css"

import SwiperCore, { Pagination, Navigation } from 'swiper';

SwiperCore.use([Pagination,Navigation]);

export default {
  name: 'Features',
  components: {
    Swiper,
    SwiperSlide,
    NextSVG,
    SectionLabel,
    UnitAccordion
  }
}
</script>

<style lang="scss" scoped>
  $divider: 1px solid color(divider);

  .c-features {
    background-color: color(bg-plinth);
  }

  .c-features__content {
    padding: 0 $margin-extra-small-mobile;
    background-color: color(bg-plinth);
    width: 100%;

    @include media-breakpoint-up(medium) {
      padding-left: $margin-large;
      padding-right: $margin-large;
    }
  }

  .c-features__headline {
    @extend %o-title;
    @extend %o-title--large;
    margin-bottom: 3rem;

    @include media-breakpoint-up(medium) {
      width: 50%;
      margin-bottom: 6rem;
    }
  }

  .c-features__section {
    padding-bottom: 4rem;
  }

  .c-features__section-logo {
    margin-bottom: 3rem;
    height: 33.5rem;

    @include media-breakpoint-up(medium) {
      flex: 50%;
      max-width: 57.7rem;
      height: auto;
      
      &--alt {
        order: 2;
      }
    }
  }

  .c-features__section-title {
    @extend %o-text;
    @extend %o-text__tag;

    padding: 1.2rem 0;
    margin-bottom: 4rem;
    border-top: $divider;
    border-bottom: $divider;
  }

  .c-features__list-container--last {
    padding-bottom: 3rem;
    border-bottom: $divider;
  }

  .c-features__unit-details {
    margin-bottom: 3rem;
    border-bottom: $divider;
    padding-left:0;
    background-image:none;
    -webkit-appearance:none;
    cursor: pointer;


    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .c-features__unit-details-icon {
    position: absolute;
    top: 50%;
    right: 0;
    display: inline-block;
    transform: translateY(-50%);

    &::after {
      @extend %o-text;
      @extend %o-text__tag;
      margin-left: 1rem;
      content: "+";
      color: color(text-black);
    }
  }

  .c-features__details {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-up(medium) {
      max-width: 66.6666%;
    }
  }

  .c-features__unit-details[open] {
    .c-features__unit-details-icon {

      &::after {
        content: "﹣";
      }
    }
  }

  .c-features__unit-details-summary {
    list-style: none;
    background-image:none;
    -webkit-appearance:none;
  }

  .c-features__unit-details-summary::-webkit-details-marker {
    display: none;
  }

  .c-features__details-title {
    @extend %o-text;
    @extend %o-text__tag;

    margin-bottom: 1.5rem;
  }

  .c-features__unit-details-info {
    @extend %o-body-text--large;

    position: relative;
    justify-content: space-between;
    padding-bottom: 0.9rem;
    color: color(text-orange);

    @include media-breakpoint-up(medium) {
      display: flex;
    }
  }

  .c-features__unit-details-price {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .c-features__unit-details-button {
    @extend %o-text;
    @extend %o-text__tag;
    margin-left: 3rem;
    margin-right: 2rem;
    color: color(text-black);
  }

  .c-features__list-container {
    margin-top: 3rem;

    @include media-breakpoint-up(medium) {
      display: flex;
      justify-content: space-between;
    }
  }

  .c-features__list {
    margin-bottom: 3em;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(medium) {
      margin-bottom: 0;
    }
  }

  .c-features__list-title {
    @extend %o-text;
    @extend %o-text--fine; 

    margin-bottom: 1rem;
    color: color(text-alt);
    text-transform: uppercase;
  }

  .c-features__list-items {
    @extend %o-body-text;
    color: color(text-alt);

    li::before {
      content: '—';
      margin-right: 1ch;
    }

    li {
      margin-bottom: 1rem;
    }

    li ul li {
      margin-left: 3ch;
    }
  }

  .c-features__sublist-title {
    display: inline-block;
    margin-bottom: 1rem;
  }

  .c-features__section-info {
    @extend %o-body-text--large;
    margin-bottom: 3rem;
    color: color(text-blue);
  }

  .c-carousel {
    width: 100%;
  }

  .c-carousel__slide {
    height: 35rem;

     @include media-breakpoint-up(medium) {
      height: 60rem;
    }

    @include media-breakpoint-up(large) {
      height: 80rem;
    }

    @include media-breakpoint-up(xlarge) {
      height: 110rem;
    }
  }

  .c-carousel__slide-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .c-carousel__gallery-controls {
    display: flex;
    flex-direction: row;
  }

  .c-carousel__gallery-control {
    width: 5rem;
    height: 5rem;
    background-color: color(gallery-controls);
  }

  .c-carousel__gallery-pagination {
    @extend %o-title;
    @extend %o-title--label;

    display: flex;
    justify-content: center;
    align-items: center;
    color: color(text-alt);
    margin-right: 1rem;
  }

  .c-carousel__gallery-next {
    box-sizing: border-box;
    padding: 1.4rem;
    cursor: pointer;
    transition: background-color 355ms ease-in;

    &:hover {
      background-color: color(bg-gum);
    }
  }

  .content {
    // padding-bottom: 4rem;
    height: 0;
    overflow: hidden;
  }

  .swiper-container {
    padding-bottom: 3rem;
  }

</style>