<template>
  <div class="c-contact" id="contact">
    <div class="c-contact__wrapper">
      <div class="c-contact__header" data-animate>
        <div>
        <SectionLabel title="schedule a tour" background="wine" text="gum" />
        <h1 class="c-contact__headline">Take a Tour From Anywhere</h1>
        <a class="c-contact__link o-link__wipe o-link__wipe--alt" target="_blank" href="https://my.matterport.com/show/?m=rVAhu8rZwFY">The Block Virtual Tour<LinkArrowSVG class="c-contact__link-arrow" /></a>
        </div>
        <BlockSVG class="c-contact__block-logo"/>
      </div>
      <div class="c-contact__content" ref="contactContent" data-animate>
        <div class="c-contact__content-column">
          <h2 class="c-contact__content-headline">
            Interested in one of our units? Reach out to book a tour.
          </h2>
          <h2 class="c-contact__content-headline">
            Contact PennHunter Management:
          </h2>
        </div>
        <div class="c-contact__content-column">
          <div class="c-contact__item">
            <h3 class="c-contact__item-title">PHONE</h3>
            <a href="tel:215-422-3980" class="c-contact__item-link o-link__wipe o-link__wipe--tertiary">215.422.3980 <ArrowSVG class="c-contact__link-arrow" /></a>
          </div>
          <div class="c-contact__item">
            <h3 class="c-contact__item-title">EMAIL</h3>
            <a href="mailto:leasing@pennhunter.com" class="c-contact__item-link o-link__wipe o-link__wipe--tertiary">leasing@&#8203;pennhunter.com <ArrowSVG class="c-contact__link-arrow" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionLabel from './SectionLabel.vue';
import BlockSVG from './BlockSVG.vue';
import ArrowSVG from './ArrowSVG.vue';
import LinkArrowSVG from './LinkArrowSVG.vue';

export default {
  name: 'Contact',
  components: {
    SectionLabel,
    BlockSVG,
    ArrowSVG,
    LinkArrowSVG
  }
}
</script>

<style lang="scss" scoped>
  .c-contact {
    position: relative;
    margin: 0 $margin-extra-small-mobile;
    
    @include media-breakpoint-up(medium) {
      margin: 0 $margin-large;
    }
  }

  .c-contact__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .c-contact__headline {
    @extend %o-title;
    @extend %o-title--large;
    color: color(text-alt);
    @include media-breakpoint-up(medium) {
      flex: 60%;
      margin: 0;
    }
  }

  .c-contact__link {
    @extend %o-body-text;
    @extend %o-body-text--large;

    display: inline-block;
    margin-bottom: 2.8rem;
    color: color(text-alt);
    text-decoration: none;
    padding: 0.3rem 0.5rem;

    &:hover {
      .c-contact__link-arrow {
        stroke: color(text-alt);
      }
    }
  }

  .c-contact__link-arrow {
    width: 2rem;
    height: 2rem;
    margin-left: 2rem;
    stroke: color(link-arrow);
    transition: all 355ms ease-in;
  }

  .c-contact__block-logo {
    width: 9.7rem;
    height: 9.7rem;

    @include media-breakpoint-up(medium) {
      margin-top: 20rem;
    }
  }

  .c-contact__content {
    padding: 5rem $margin-extra-small-mobile;
    background-color: color(bg-gum);

    @include media-breakpoint-up(medium) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 8.8rem $margin-extra-small;
    }
  }

  .c-contact__content-column {
    flex: 1;
  }

  .c-contact__content-headline {
    @extend %o-body-text;
    @extend %o-body-text--large;

    margin-bottom: 4.5rem;
    color: color(text-alt);

    @include media-breakpoint-up(medium) {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .c-contact__item {
    margin-bottom: 5rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(medium) {
      flex: auto;
    }
  }

  .c-contact__item-title {
    @extend %o-text;
    @extend %o-text__tag;
  }

  .c-contact__item-link {
    @extend %o-body-text;
    @extend %o-body-text--large;

    display: inline-flex;
    flex-direction: row;
    align-items: center;
    color: color(text-link);
    text-decoration: none;
    padding: 1.2rem 1rem 0.9rem 0;
    border-bottom: 1px solid color(divider);
    font-size: 2.8rem;

    @include media-breakpoint-up(medium) {
      font-size: 3.4rem;
    }

    &:hover {
      .c-contact__link-arrow {
        stroke: color(text-light);
      }
    }
  }

  .c-contact__link-arrow {
    width: 2.6rem;
    height: 2.6rem;
    margin-left: 1.4rem;
    transition: all 355ms ease-in;
  }
</style>