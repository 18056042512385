<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" preserveAspectRatio="xMinYMin meet">
    <path stroke="#681D24" stroke-width="1" d="M15.435 14.85L1.293.708M1.293 14.85L15.435.707"/>
  </svg>
</template>

<script>
export default {
  name: 'CloseSVG'
}
</script>