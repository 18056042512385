<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139 16">
    <path d="M4.339 10.823l-1.44 4.005H.7l5.026-13.77h2.252l5.026 13.77h-2.199l-1.414-4.005H4.34zm2.513-7.408L4.942 8.99h3.821l-1.91-5.576zM17.219 1.059h2.172v11.806h4.817v1.963h-6.99V1.058zM28.659 1.059h2.172v11.806h4.817v1.963h-6.99V1.058zM40.098 1.059h2.173v13.77h-2.173V1.058zM50.151 10.823l-1.44 4.005h-2.199l5-13.77h2.252l5.026 13.77h-2.2l-1.413-4.005h-5.026zm2.513-7.408l-1.91 5.576h3.821l-1.91-5.576zM63.005 1.059h2.199l6.754 10.235V1.06h2.146v13.77h-2.173l-6.78-10.184v10.183h-2.146V1.058zM90.178 13.65a7.2 7.2 0 01-4.32 1.44c-4.162 0-7.094-3.089-7.094-7.146 0-4.058 3.037-7.147 7.068-7.147a6.812 6.812 0 014.162 1.335l-.654 1.937c-.602-.445-1.859-1.23-3.456-1.23-2.88 0-4.869 2.251-4.869 5.105 0 2.853 1.937 5.104 4.87 5.104 1.78 0 3.062-.89 3.612-1.309l.68 1.911zM94.811 1.059h6.807v1.963h-4.634V6.87h4.005v1.937h-4.005v4.058h4.817v1.963h-7.016V1.058h.026zM114.262 1.059h2.173V6.87h6.544V1.06h2.173v13.77h-2.173v-5.97h-6.544v5.97h-2.173V1.058zM130.597 1.059h4.005c2.592 0 4.398 1.649 4.398 4.136 0 2.434-1.885 4.188-4.398 4.188h-1.832v5.445h-2.173V1.058zm3.743 6.413c1.44 0 2.409-.916 2.409-2.277 0-1.362-.995-2.225-2.409-2.225h-1.57v4.502h1.57z" fill="#FF8000"/>
  </svg>
</template>

<script>
export default {
  name: 'AllianceSVG'
}
</script>