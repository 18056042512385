<template>
  <div class="c-footer" :style="footerStyle">
    <!-- <div class="c-footer__apply-container">
      <SectionLabel title="Apply" background="wine" text="gum" />
      <div>
        <a class="c-apply__link o-link__wipe o-link__wipe--alt" target="_blank" href="https://alliancehp.com">
          <span class="c-apply__link-text">Start Your Application</span>
          <LinkArrowSVG class="c-apply__link-arrow" />
        </a>
      </div>
    </div> -->
    <div class="c-footer__nav" data-animate>
      <ul class="c-footer__nav-items">
        <li class="c-footer__nav-item"><a href="#the-building" class="o-link__wipe o-link__wipe--quaternary">Building</a></li>
        <li class="c-footer__nav-item"><a href="#neighborhood" class="o-link__wipe o-link__wipe--quaternary">Neighborhood</a></li>
        <li class="c-footer__nav-item"><a href="#features" class="o-link__wipe o-link__wipe--quaternary">Features</a></li>
        <li class="c-footer__nav-item"><a href="#about" class="o-link__wipe o-link__wipe--quaternary">About</a></li>
        <li class="c-footer__nav-item"><a href="#contact" class="o-link__wipe o-link__wipe--quaternary">Schedule a Tour</a></li>
      </ul>
    </div>
    <div class="c-footer__lower " data-animate>
      <LogoSmall class="c-footer__logo"/>
      <Logo class="c-footer__large-logo" />
      <address class="c-footer__address">456 N. 5TH ST  •  SoNo <br>Philadelphia, PA</address>
      <div class="c-footer__info">
        <SocialIcons />
        <div class="c-footer__company-logo-container">
          <a href="https://www.alliancehp.com/">
            <AllianceSVG class="c-footer__company-logo"/>
          </a>
        </div>
        <small class="c-footer__copyright">
          (c) 2022 The block
        </small>
      </div>
    </div>
  </div>
</template>

<script>
// import SectionLabel from './SectionLabel.vue';
import LogoSmall from './LogoSmall.vue';
import Logo from './Logo.vue';
import AllianceSVG from './AllianceSVG.vue';
import SocialIcons from './SocialIcons.vue';
// import LinkArrowSVG from './LinkArrowSVG.vue';

export default {
  name: 'Footer',
  components: {
    // SectionLabel,
    LogoSmall,
    Logo,
    SocialIcons,
    AllianceSVG
    // LinkArrowSVG
  },
  props: {
    contactHeight: String
  },
  computed: {
    footerStyle () {
      return `padding-top: ${this.paddingTop}px`
    }
  },
  data () {
    return {
      paddingTop: 0
    }
  },
  updated () {
    this.paddingTop = this.contactHeight / 2;
  }
}
</script>

<style lang="scss" scoped>
  $footer-divider: 1px solid color(divider-light);

  .c-footer {
    padding: $margin-extra-small-mobile 3.8rem;
    background-color: color(bg-green);


    @include media-breakpoint-up(medium) {
      padding: 15.8rem $margin-extra-small 5.2rem !important;
    }
  }

  .c-footer__apply-container {
    margin: 4rem 0;
    text-align: center;
  }

  .c-apply__link {
    @extend %o-title;
    @extend %o-title--large;
    margin-bottom: 2.8rem;
    color: color(text-tertiary);
    text-decoration: none;
    padding: 0.3rem 0.9rem;

    &:hover {
      .c-apply__link-arrow {
        stroke: color(text-alt);
      }
    }
  }

  .c-apply__link-text {
    max-width: 260px;

    @include media-breakpoint-up(medium) {
      max-width: 500px;
    }
  }

  .c-apply__link-arrow {
    width: 2rem;
    height: 2rem;
    margin-left: 1rem;
    stroke: color(link-arrow);
    transition: all 355ms ease-in;

    @include media-breakpoint-up(medium) {
      width: 2.5rem;
      height: 2.5rem;
      margin-bottom: 1.5rem;
      margin-left: 2rem;
    }
  }


  .c-footer__nav {
    display: flex;
    margin-bottom: 4.2rem;
    margin-top: 5rem;
    border-top: $footer-divider;
    
    @include media-breakpoint-up(medium) {
      justify-content: center;
      margin-bottom: 8.2rem;
      margin-top: 0;
      padding-top: 5rem;
    }
  }

  .c-footer__nav-items {
    width: 100%;

    @include media-breakpoint-up(medium) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 62.8rem;
      margin-bottom: 2.5rem;
    }
  }

  .c-footer__nav-item {
    border-bottom: $footer-divider;
    text-align: center;
    padding: 1.5rem 0;

    a {
      @extend %o-text;
      @extend %o-text__tag;
      color: color(text-link-alt);
      text-decoration: none;
      padding: 0.2rem 0.5rem;
    }

    @include media-breakpoint-up(medium) {
      padding: 0;
      border-bottom: none;
    }
  }

  .c-footer__logo {
    margin-bottom: 1.8rem;
    fill: color(bg-purple);
    
    @include media-breakpoint-up(medium) {
      width: 7.3rem;
      height: auto;
      margin-bottom: 2.6rem;
    }
  }

  .c-footer__lower {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .c-footer__large-logo {
    width: 24.2rem;
    margin-bottom: 1.7rem;
    fill: color(bg-purple);

    @include media-breakpoint-up(medium) {
      width: 34.8rem;
      height: auto;
      margin-bottom: 1.86rem;
    }
  }

  .c-footer__address {
    @extend %o-text;
    @extend %o-text--fine;

    color: color(text-tertiary);
    margin-bottom: 5.4rem;
    text-align: center;
    font-style: normal;
  }

  .c-footer__company-logo-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 2.1rem;
    margin-bottom: 3rem;
    border-bottom: $footer-divider;

    @include media-breakpoint-up(medium) {
      order: 3;
      margin: 0;
      padding: 0;
      border: none;
    }
  }

  .c-footer__company-logo {
    width: 13.8rem;
    height: auto;

    @include media-breakpoint-up(medium) {
      width: 15.37rem;
      height: 1.58rem;
    }
  }

  .c-footer__copyright {
    @extend %o-text;
    @extend %o-text__tag;

    display: flex;
    justify-content: center;
    color: color(text-orange);

    @include media-breakpoint-up(medium) {
      order: 1;
    }
  }

  .c-footer__info {
    width: 100%;

    @include media-breakpoint-up(medium) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 3rem;
      align-items: center;
      border-top: $footer-divider;
      div,
      small {
        flex: 33%;
      }
    }
  }
</style>