<template>
  <div class="c-neighborhood" id="neighborhood">
    <div class="c-neighborhood__content c-section__content" data-section-content>
      <SectionLabel title="the neighborhood" background="wine" text="white" class="c-neighborhood__section-label" data-animate />
      <div class="c-neighborhood__upper" data-animate>
        <h1 class="c-neighborhood__headline">a neighborhood above the rest</h1>
        <p class="c-neighborhood__description">
          The Block is built on a foundation of convenience with easy access to countless amenities in SoNo.
        </p>
      </div>
      <!-- Purposefully commenting this out. Not including this carousel until the client provides new images. -->
      <!-- <div class="c-neighborhood_gallery" data-animate>
        <div class="swiper-container" data-carousel>
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="slide in carouselImages" :key="slide.url"><img class="c-neighborhood__gallery-image" :src="slide.url" :alt="slide.caption"></div>
          </div>
          <div class="c-neighborhood__gallery-controls">
            <div class="c-neighborhood__gallery-control c-neighborhood__gallery-pagination"></div>
            <div class="c-neighborhood__gallery-control c-neighborhood__gallery-next">
              <NextSVG />
            </div>
            <div class="c-neighborhood_gallery-details">
              <p class="c-neighborhood__gallery-caption-number">FIG. {{ formatNumber(currentSlidePosition + 1) }} --</p>
              <p class="c-neighborhood__gallery-caption">{{ currentGallerySlide.caption }}</p>
            </div>
          </div>
        </div>

      </div> -->
      <div class="c-neighborhood__map-section" data-animate>
        <div class="c-neighborhood__map-section-details">
          <div class="c-neighborhood__headline c-neighborhood__map-headline">get to "no" sono</div>
          <p class="c-neighborhood__description c-neighborhood__map-description">{{ mapCopy }}</p>
        </div>
        <div class="c-neighborhood__map-container">
          <div class="c-neighborhood__map">
            <img src="/images/map.png" alt="">
          </div>
          <div class="c-neighborhood__map-legend">
            <ol class="c-neighborhood__list">
              <li class="c-neighborhood__list-item" v-for="location in mapLocations" :key="location.name">
                <div class="c-neighborhood__list-item-title">{{ location.name }}</div>
                <span class="c-neighborhood__list-item-location">{{ location.distance }}</span>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionLabel from './SectionLabel.vue';
// Uncomment this when adding carousel back
// import NextSVG from './NextSVG.vue';

// import Swiper, {Navigation, Pagination} from 'swiper';
// Swiper.use([Navigation, Pagination]);
// import 'swiper/swiper.scss';
// import "swiper/components/navigation/navigation.min.css"

export default {
  name: 'Neighborhood',
  components: {
    SectionLabel
    // Also need to uncomment this and add comma above when adding carousel back
    // NextSVG
  },
  // mounted () {
  //   this.mySwiper.init();
  // },
  // created () {
  //   this.currentGallerySlide = this.carouselImages[0];
  // },
  // computed: {
  //   currentSlidePosition () {
  //     return this.carouselImages.findIndex(slide => { 
  //       return slide.url === this.currentGallerySlide.url;
  //     })
  //   }
  // },
  data () {
    return {
      // mySwiper: new Swiper('.swiper-container', {
      //   slidesPerView: 1,
      //   spaceBetween: 20,
      //   loop: true,
      //   breakpoints: {
      //     960: {
      //       slidesPerView: 2
      //     }
      //   },
      //   pagination: {
      //     el: ".c-neighborhood__gallery-pagination",
      //     type: "fraction",
      //   },
      //   navigation: {
      //     nextEl: ".c-neighborhood__gallery-next"
      //   },
      //   on: {
      //     slideChange: () => {
      //       this.currentGallerySlide = this.carouselImages[this.mySwiper.realIndex];
      //     }
      //   }
      // }),
      mapCopy: "The Block is based in the hip SoNo area next to Target, Yards and plenty of parking.",
      // carouselImages: [
      //   {
      //     name: 'image1',
      //     url: '/images/gallery-1.jpg',
      //     caption: 'The Cabin is a warm, woody common space that feels like a Scandanavian retreat.'
      //   },
      //   {
      //     name: 'image2',
      //     url: '/images/gallery-2.jpg',
      //     caption: 'The Common Lounge is a warm, woody, flexible space that is open to all tenants.'
      //   }
      // ],
      mapLocations: [
        {
          name: 'Yards',
          distance: '0.1 MI'
        },
        {
          name: 'Target & Starbucks',
          distance: '0.1 MI'
        },
        {
          name: 'Franklin Music Hall',
          distance: '0.3 MI'
        },
        {
          name: 'Silk City',
          distance: '0.1 MI'
        },
        {
          name: 'Union Transfer',
          distance: '0.6MI'
        },
        {
          name: 'The Lucky Well',
          distance: ' 0.5 MI'
        },
        {
          name: 'Roy Pitz Barrel House',
          distance: '0.6 MI'
        },
        {
          name: 'Triple Bottom Brewing',
          distance: '0.6 MI'
        },
        {
          name: 'La Chinesca',
          distance: '0.6 MI'
        },
        {
          name: 'Abbaye Bar',
          distance: '0.4 MI'
        },
        {
          name: 'North Third',
          distance: '0.5 MI'
        },
        {
          name: "Honey's Sit n' Eat",
          distance: '0.4 MI'
        },
        {
          name: 'Federal Donuts',
          distance: '0.5 MI'
        },
        {
          name: 'GoPuff HQ',
          distance: '0.3 MI'
        },
        {
          name: "Stripp'd Juice",
          distance: '0.4 MI'
        },
        {
          name: "Morgan's Pier",
          distance: '0.7 MI'
        }
      ],
      currentGallerySlide: null
    }
  }
  // methods: {
  //   formatNumber (number) {
  //     if (number < 10) {
  //       return `0${number}`;
  //     } else {
  //       return number;
  //     }
  //   }
  // }
}
</script>

<style lang="scss" scoped>
  $list-divider: 1px solid color(divider);

  .c-neighborhood__content {
    position: relative;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8.2rem 0 0;
    padding-bottom: 8.2rem;
    background: linear-gradient(0deg, $plinth 0%, $plinth 10%, $white 100%);

    @include media-breakpoint-up(medium) {
      margin: 13.6rem 0 0;
      padding-bottom: 13.8rem;
      align-items: flex-start;
    }
  }

  .c-neighborhood__section-label {
    @include media-breakpoint-up(medium) {
      margin-left: $margin-large;
    }
  }

  .c-neighborhood__upper {
    margin: 0 $margin-extra-small-mobile 6rem;

    @include media-breakpoint-up(medium) {
      display: flex;
      flex-direction: row;
      margin: 0 $margin-large $margin-large;
    }
  }

  .c-neighborhood__headline {
    @extend %o-title;
    @extend %o-title--large;
    margin-bottom: 2.3rem;

    @include media-breakpoint-up(medium) {
      flex: 71%;
      padding-right: 25.1rem;
      margin-bottom: 0;
    }
  }

  .c-neighborhood__description {
    @extend %o-body-text;
    color: color(text-alt);

    @include media-breakpoint-up(medium) {
      flex: 29%;
      align-self: flex-end;
    }
  }

  .c-neighborhood_gallery {
    width: 100%;
    margin: 0 0 9.5rem $margin-extra-small-mobile;

    .swiper-slide-next {
      opacity: 0.3;
    }

    @include media-breakpoint-up(medium) {
      margin-left: $margin-large;
      margin-bottom: 19.4rem;
    }
  }

  .c-neighborhood__gallery-image {
    @include media-breakpoint-up(medium) {
      width: auto;
    }
  }

  .c-neighborhood__gallery-controls {
    display: flex;
    flex-direction: row;
    transform: translateY(-3px);
  }

  .c-neighborhood__gallery-control {
    width: 5rem;
    height: 5rem;
    background-color: color(gallery-controls);
  }

  .c-neighborhood__gallery-pagination {
    @extend %o-title;
    @extend %o-title--label;

    display: flex;
    justify-content: center;
    align-items: center;
    color: color(text-alt);
    margin-right: 1rem;
  }

  .c-neighborhood__gallery-next {
    width: 5rem;
    height: 5rem;
    background-color: color(gallery-controls);
    padding: 1.4rem;
    margin-right: 1.3rem;
    cursor: pointer;
    transition: background-color 355ms ease-in;

    &:hover {
      background-color: color(bg-gum);
    }

    @include media-breakpoint-up(medium) {
      margin-right: 7.1rem;
    }
  }

  .c-neighborhood__map-section {
    @include media-breakpoint-up(medium) {
      display: flex;
      flex-direction: row;
    }
  }

  .c-neighborhood__map-container {
    @include media-breakpoint-up(medium) {
      display: flex;
      flex-direction: column;
    }
  }

  .c-neighborhood__map {
    margin-bottom: 4.6rem;
  }

  .c-neighborhood__map-headline {
    padding-right: 0;
    margin-bottom: 3rem;
  }

  .c-neighborhood__map-description {
    width: 80%;
  }

  .c-neighborhood__map-section-details {
    margin: 0 $margin-extra-small-mobile 4.8rem;

    p:not(:last-of-type) {
      margin-bottom: 4.8rem;
    }

    @include media-breakpoint-up(medium) {
      order: 2;
      margin: 0 9.6rem 0 7.5rem;
    }
  }

  .c-neighborhood__list {
    margin: 0 $margin-extra-small-mobile;
    list-style-type: none;
    counter-reset: number;

    @include media-breakpoint-up(medium) {
      display: grid;
      grid-auto-flow: column;
      grid-template-rows: repeat(4, 1fr);
      column-gap: 3.5rem;
      margin: 0 0 0 $margin-extra-small;

    }
  }

  .c-neighborhood__list-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 0;
    border-bottom: $list-divider;

    &:first-of-type {
      border-top: $list-divider;
    }
    
    &::before {
      @extend %o-text;
      @extend %o-text__tag;
      width: 3.3rem;
      counter-increment: number;
      content: counter(number)"\a0";
      color: color(text-alt);
    }

    @include media-breakpoint-up(medium) {
      &:nth-child(4n+1) {
        border-top: $list-divider;
      }
    }
  }

  .c-neighborhood__list-item-title {
    @extend %o-body-text;
    @extend %o-body-text--small;

    margin-right: 0.5rem;
  }

  .c-neighborhood__list-item-location {
    @extend %o-text;
    @extend %o-text--small;
    margin-left: auto;
    flex-shrink: 0;
  }

  .c-neighborhood_gallery-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: color(text-alt);

    @include media-breakpoint-up(medium) {
      flex-direction: row;
      align-items: center;
    }
  }

  .c-neighborhood__gallery-caption-number {
    @extend %o-body-text;
    @extend %o-body-text--figure;

    @include media-breakpoint-up(medium) {
      margin-right: 5rem;
    }
  }

  .c-neighborhood__gallery-caption {
    @extend %o-body-text;
  }
</style>