<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" preserveAspectRatio="xMinYMin meet" stroke="#E8D2C9">
    <path d="M1 1h20m0 0v20m0-20L1.003 21" stroke-width="2"/>
  </svg>
</template>

<script>
export default {
  name: 'LinkArrowSVG'
}
</script>