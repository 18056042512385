<template>
  <div class="c-nav" :class="{ 'c-nav--active': isActive }">
    <Logo class="c-nav__logo" v-if="isActive" />
    <div class="c-nav__links-wrapper">
      <ul class="c-nav__links o-text o-text__tag" :class="{ 'c-nav__links--active': isActive }">
        <li class="c-nav__item"><a class="o-link__wipe" href="#the-building" @click="hide">Building</a></li>
        <li class="c-nav__item"><a class="o-link__wipe" href="#neighborhood" @click="hide">Neighborhood</a></li>
        <li class="c-nav__item"><a class="o-link__wipe" href="#features" @click="hide">Features</a></li>
        <li class="c-nav__item"><a class="o-link__wipe" href="#about" @click="hide">About</a></li>
        <li class="c-nav__item"><a class="o-link__wipe" href="#contact" @click="hide">Schedule A Tour</a></li>
      </ul>
    </div>
    <SocialIcons class="c-nav__social" v-if="isActive" />
    <div class="c-nav__copyright" v-if="isActive">
      (c) 2021 The block At SONO
    </div>
    <div class="c-nav__toggle" @click="toggle">
      <CloseMobileSVG class="c-nav__close" v-if="isActive" />
      <Hamburger v-else/>
    </div>
  </div>
</template>

<script>
import Hamburger from './Hamburger.vue';
import CloseMobileSVG from './CloseMobileSVG.vue';
import Logo from './Logo.vue';
import SocialIcons from './SocialIcons.vue'

export default {
  name: 'Navigation',
  components: {
    Hamburger,
    CloseMobileSVG,
    Logo,
    SocialIcons
  },
  data () {
    return {
      isActive: false
    }
  },
  methods: {
    toggle() {
      this.isActive = this.isActive ? false : true;
    },
    hide() {
      this.isActive = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .c-nav {
    display: flex;
    flex-direction: column;
    padding: 0 $margin-extra-small-mobile;
    background-color: color(bg-orange);
    
    &--active {
      z-index: zindex(nav);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      background-color: color(bg-green);
      padding-bottom: 3.6rem;

      .c-nav__toggle svg {
        margin-top: 3.3rem;
      }
    }

    @include media-breakpoint-up(medium) {
      flex-direction: row;
      justify-content: center;
      padding: 0 $margin-extra-small;
    }
  }

  .c-nav__toggle {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    order: 1;
    height: 8.3rem;

    @include media-breakpoint-up(medium) {
      display: none;
      height: auto;
    }
  }

  .c-nav__close {
    height: 3.5rem;
    width: 3.5rem;
  }

  .c-nav__logo {
    order: 2;
    margin-bottom: 8.9rem;
    fill: color(bg-orange);

    @include media-breakpoint-up(medium) {
      display: none;
    }
  }

  .c-nav__links {
    display: none;
    margin-bottom: 4rem;
    text-align: center;

    &--active {
      display: block;
    }

    @include media-breakpoint-up(medium) {
      display: block;
    }
  }

  .c-nav__links-wrapper {
    order: 3;
    @include media-breakpoint-up(medium) {
      width: 100%;
      margin-top: 4.3rem;
      border-top: 1px solid color(divider);
    }
  }

  .c-nav__item {
    border-top: 1px solid color(divider-light);
    padding: 1.9rem 0 2.8rem;
    a {
      color: color(text-light);
      text-decoration: none;
    }

    &:last-of-type {
      border-bottom: 1px solid color(divider-light);
    }

    @include media-breakpoint-up(medium) {
      display: inline-block;
      margin-right: 4.7rem;
      border: none;

      &:last-of-type {
        margin-right: 0;
        border: none;
      }

      a {
        padding: 0.3rem 1rem 0.1rem;
        color: color(text-nav);
      }
    }
  }

  .c-nav__social {
    order: 4;
    padding: 0;
    margin-bottom: 8.5rem;
    border: none;
    @include media-breakpoint-up(medium) {
      display: none;
    }
  }

  .c-nav__copyright {
    @extend %o-text;
    @extend %o-text__tag;

    order: 5;
    color: color(text-orange);
    text-align: center;
  }
</style>