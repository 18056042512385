<template>
  <div class="c-accordion" :class="{ 'c-accordion--expanded': isExpanded }">
    <div class="c-accordion__header" @click="toggle">
      {{ title }}
      <CloseSVG class="c-accordion__toggle" v-if="isExpanded"/>
      <ExpandSVG class="c-accordion__toggle" v-else />
    </div>
    <div class="c-accordion__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import CloseSVG from './CloseSVG.vue';
import ExpandSVG from './ExpandSVG.vue';

export default {
  name: 'accordion',
  components: {
    CloseSVG,
    ExpandSVG
  },
  props: {
    title: String
  },
  data () {
    return {
      isExpanded: false
    }
  },
  methods: {
    toggle() {
      this.isExpanded = this.isExpanded ? false : true;
    }
  }
}
</script>

<style lang="scss">
  .c-accordion {
    &--expanded {
      .c-accordion__header {
        margin-bottom: 2.8rem;
        border-bottom: 1px solid color(divider);
      }

      .c-accordion__content {
        height: auto;
        overflow: visible;
      }
    }
  }

  .c-accordion:last-of-type {
    .c-accordion__header {
      border-bottom: 1px solid color(divider);
    }
  }

  .c-accordion__header {
    @extend %o-text;
    @extend %o-text__tag;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;
    border-top: 1px solid color(divider);
    cursor: pointer;
  }

  .c-accordion__toggle {
    height: 2rem;
  }

  .c-accordion__content {
    height: 0;
    overflow: hidden;
  }
</style>