<template>
    <svg class="c-hamburger" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 11">
      <path stroke="#681D24" d="M0 10.5h50M0 .5h50"/>
    </svg>
</template>

<script>
export default {
  name: 'Hamburger'
}
</script>

<style lang="scss" scoped>
  .c-hamburger {
    width: 5rem;
  }
</style>