<template>
  <div class="c-hero">
    <div class="c-hero__logo" data-animate>
      <Logo />
    </div>
    <div class="c-hero__content">
      <div class="c-hero__details" data-animate>
        <h1 class="c-hero__headline">designed for life at the edge</h1>
        <div class="c-hero__address-container">
          <div class="c-hero__address-circle"></div>
          <address class="c-hero__address">
            <span class="c-hero__address-part">456 N. 5th</span>
            <span class="c-hero__address-part">Philadelphia, PA 19123</span>
            <span class="c-hero__address-part">{{ time }}</span>
          </address>
        </div>
      </div>
      <div class="c-hero__image-container">
        <img class="c-hero__image" src="/images/the-block-building.jpg" alt="Front building facade" data-animate />
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment';
import Logo from './Logo.vue';

export default {
  name: 'Hero',
  components: {
    Logo
  },
  data () {
    return {
      time: Moment().format('h:mm A')
    }
  },
  methods: {
    setTime() {
      setInterval(() => {
        this.time = Moment().format('h:mm A');
      }, 1000);
    }
  },
  created() {
    this.setTime();
  }
}
</script>

<style lang="scss">
  .c-hero {
    z-index: zindex(float);
    position: relative;
    background-color: color(bg-orange);
  }

  .c-hero__logo {
    margin: 0 $margin-extra-small-mobile 5rem;
    opacity: 0;

    @include media-breakpoint-up(medium) {
      margin: 0 $margin-extra-small 5.4rem;
    }
  }

  .c-hero__content {
    @include media-breakpoint-up(medium) {
      display: flex;
    }
  }

  .c-hero__details {
    margin: 0 $margin-extra-small-mobile;
    opacity: 0;
    transition-delay: 700ms !important;

    @include media-breakpoint-up(medium) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 42%;
      margin: 0 4.3rem 0 $margin-extra-small;
      padding-top: 10.6rem;
    }
  }

  .c-hero__headline {
    @extend %o-title;
    @extend %o-title--large;
    margin-bottom: 5.2rem;
  }

  .c-hero__address-container {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid color(divider);

    @include media-breakpoint-up(medium) {
      margin-bottom: 3.9rem;
    }
  }

  .c-hero__address {
    @extend %o-text;
    @extend %o-text__tag;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    font-style: normal;

    @include media-breakpoint-up(medium) {
      flex-direction: row;
    }
  }

  .c-hero__address-part {
     @include media-breakpoint-up(medium) {
      margin-right: 3.4rem;
    }
  }

  .c-hero__address-circle {
    content: '';
    height: 2rem;
    width: 2rem;
    margin-right: 1.5rem;
    background-color: color(address-dot);
    border-radius: 50%;
  }

  .c-hero__image-container {
    position: relative;
    flex: 58%;
    padding-left: $margin-extra-small-mobile;
  }

  .c-hero__image {
    transform: translateY(2rem);
    opacity: 0;
    transition-delay: 1400ms !important;

    @include media-breakpoint-up(medium) {
      width: 100%;
      margin-left: 0;
      transform: translateY(5rem);
    }
  }
</style>