<template>
  <Navigation />
  <Hero class="c-section" />
  <Building class="c-section" />
  <Neighborhood class="c-section" />
  <Features class="c-section" />
  <div class="o-layout--wrapper o-layout__gradient--orange" :style="containerStyle" ref="aboutContactContainer">
    <About class="c-section" />
    <Contact />
  </div>
    <Footer :contactHeight="computedContactHeight" />
</template>

<script>
// import SwiperCarousel from './components/SwiperCarousel.vue';
import Navigation from './components/Navigation.vue';
import Hero from './components/Hero.vue';
import Building from './components/Building.vue';
import Neighborhood from './components/Neighborhood.vue';
import Features from './components/Features.vue';
import About from './components/About.vue';
import Contact from './components/Contact.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    // SwiperCarousel,
    Navigation,
    Hero,
    Building,
    Neighborhood,
    Features,
    About,
    Contact,
    Footer
  },
  computed: {
    containerStyle () {
      return `margin-bottom: -${this.containerNegativeMargin}px`;
    }
  },
  data () {
    return {
      containerNegativeMargin: 0,
      computedContactHeight: 0
    }
  },
  mounted () {
    const contactHeight = this.$refs.aboutContactContainer.querySelector('.c-contact__content').clientHeight;
    this.computedContactHeight = contactHeight;
    this.containerNegativeMargin = contactHeight / 2;

    const observerOptions = {
          root: null,
          rootMargin: '0px',
          threshold: 0.1
        };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const element = entry.target;
          element.classList.add('c-animate--visible');
        }
      });
    }, observerOptions);

    const sections = document.querySelectorAll('[data-animate]');

    sections.forEach(section => {
      section.classList.add('c-animate');
      observer.observe(section);
    });
  }
}
</script>
