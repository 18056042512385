<template>
  <div class="c-section-label" :class="['c-section-label--bg-' + background, 'c-section-label--text-' + text]">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'SectionLabel',
  props: {
    title: String,
    background: String,
    text: String
  }
}
</script>

<style lang="scss" scoped>
  .c-section-label {
    @extend %o-text;
    @extend %o-text__tag;
    display: inline-block;
    padding: 0.1rem 0.5rem;
    margin-bottom: 5rem;

    &--bg-wine {
      background-color: color(section-label-wine);
    }
    &--bg-gum {
      background-color: color(section-label-gum);
    }
    &--text-gum {
      color: color(section-label-gum);
    }
    &--text-white {
      color: color(text-inverse);
    }
    &--text-wine {
      color: color(section-label-wine);
    }
    &--text-plinth {
      color: color(section-label-plinth);
    }
  }
</style>