<template>
  <svg class="c-logo-small" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 76">
    <path d="M77.844 16.924L61.864 0H42.868l-6.118 6.553-4.991-5.226H16.14L0 18.25v33.888h7.809v5.91h8.01v5.748h7.848v5.548h7.648v6.11l.04.04 53.292-10.894L90 49.647 77.844 16.924zM17.67 4.864h12.558l6.52 6.834 1.208-1.286 6.44-6.874h15.9L74.261 18.33v30.27H3.542V19.659l3.462-3.498 1.247-1.326 9.419-9.97z" />
    <path d="M30.43 23.919l-6.843-4.784-6.843 4.784v10.412H30.43V23.919zM60.537 23.517l-8.17-5.708-8.172 5.708v10.814h16.342V23.517z" />
  </svg>
</template>

<script>
export default {
  name: 'LogoSmall'
}
</script>

<style lang="scss" scoped>
  .c-logo-small {
    width: 9rem;
    height: 7.55rem;

    @include media-breakpoint-up(medium) {
      width: 19rem;
      height: 15.9rem;
    }
  }

  
</style>