<template>
  <div class="c-accordion__details" :class="{ 'c-accordion--expanded': isExpanded }">
    <div class="c-accordion__details-summary" @click="toggle">
      <h3 class="c-accordion__details-title">{{ title }}</h3>
      <div class="c-accordion__details-info">
        <span>{{ size }}</span>
        <div class="c-accordion__details-price">
            <span>{{ price }}</span>
            <div class="c-accordion__details-button">
                <span class="c-accordion__details-button-text">{{ button }}</span>
                <span class="c-accordion__details-button-icon">
                    <span v-if="isExpanded">-</span>
                    <span v-else>+</span>
                </span>
            </div>
        </div>
      </div>
    </div>
    <div class="c-accordion__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'accordion',
  props: {
    title: String,
    price: String,
    size: String,
    button: String
  },
  data () {
    return {
      isExpanded: false
    }
  },
  methods: {
    toggle() {
      this.isExpanded = this.isExpanded ? false : true;
    }
  }
}
</script>

<style lang="scss" scoped>
  $divider: 1px solid color(divider);

  .c-accordion__details {
    margin-bottom: 3rem;
    border-bottom: $divider;
    padding-left: 0;


    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .c-accordion__details-summary {
      cursor: pointer;
  }

  .c-accordion__header {
    @extend %o-text;
    @extend %o-text__tag;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;
    border-top: 1px solid color(divider);
    cursor: pointer;
  }

  .c-accordion__details-title {
    @extend %o-text;
    @extend %o-text__tag;

    margin-bottom: 1.5rem;
  }

  .c-accordion__details-info {
    @extend %o-body-text--large;

    position: relative;
    justify-content: space-between;
    padding-bottom: 0.9rem;
    color: color(text-orange);

    @include media-breakpoint-up(medium) {
      display: flex;
    }
  }

  .c-accordion__details-button {
    @extend %o-text;
    @extend %o-text__tag;
    display: flex;
    margin-left: 3rem;
    color: color(text-black);
  }

  .c-accordion__details-button-text {
    display: inline-block;
    margin-right: 1.5rem;
  }

  .c-accordion__details-button-icon {
    position: absolute;
    display: inline-block;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }


  .c-accordion__details-price {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

    @include media-breakpoint-up(medium) {
      margin-top: 0;
    }
  }

  .c-accordion__toggle {
    width: 1.2rem;
    height: 1.2rem;
  }

  .c-accordion__content {
    height: 0;
    overflow: hidden;
  }
</style>