<template>
  <svg fill="#FF8000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
    <path d="M9 18.516c-2.46 0-2.75-.013-3.71-.054a6.734 6.734 0 01-2.185-.438 4.56 4.56 0 01-2.63-2.563 6.274 6.274 0 01-.419-2.13C0 12.398 0 12.09 0 9.714 0 7.31.013 7.03.056 6.1a6.273 6.273 0 01.419-2.127 4.552 4.552 0 012.633-2.564 6.686 6.686 0 012.184-.41C6.247.948 6.561.948 9 .948c2.48 0 2.765.013 3.71.053.749.014 1.49.152 2.19.409a4.557 4.557 0 012.633 2.564c.267.69.41 1.421.421 2.16.056.932.056 1.237.056 3.614s-.014 2.688-.056 3.61a6.283 6.283 0 01-.42 2.133 4.566 4.566 0 01-2.634 2.564c-.7.255-1.439.393-2.186.408-.955.054-1.268.054-3.714.054zM8.966 2.489c-2.446 0-2.7.012-3.655.054-.57.007-1.135.11-1.669.303-.79.294-1.414.9-1.719 1.667a4.773 4.773 0 00-.311 1.644c-.053.943-.053 1.19-.053 3.557 0 2.338.009 2.621.053 3.56.009.555.114 1.105.311 1.626.306.767.93 1.372 1.719 1.667a5.036 5.036 0 001.669.302c.968.055 1.223.055 3.655.055 2.453 0 2.707-.012 3.654-.055a5.08 5.08 0 001.67-.302A2.962 2.962 0 0016 14.902c.2-.527.305-1.083.311-1.644h.011c.043-.932.043-1.18.043-3.56s-.011-2.63-.054-3.56A4.812 4.812 0 0016 4.513a2.962 2.962 0 00-1.71-1.667 5.06 5.06 0 00-1.67-.303c-.967-.054-1.22-.054-3.654-.054zM9 14.213c-1.87.001-3.557-1.095-4.273-2.777a4.418 4.418 0 011-4.909 4.713 4.713 0 015.038-.978c1.728.697 2.855 2.339 2.855 4.16-.003 2.485-2.069 4.5-4.62 4.504zm0-7.428c-1.657 0-3 1.308-3 2.922s1.343 2.922 3 2.922 3-1.308 3-2.922c-.004-1.612-1.345-2.918-3-2.922zm4.8-.695c-.595-.002-1.075-.472-1.074-1.052.001-.579.483-1.048 1.078-1.048.595 0 1.077.47 1.078 1.048 0 .28-.114.548-.317.745a1.094 1.094 0 01-.765.307z" />
  </svg>
</template>

<script>
export default {
  name: 'Instagram'
}
</script>