<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" preserveAspectRatio="xMinYMin meet">
    <path d="M13.409 3.409l8.033 8.033m0 0l-8.033 8.034m8.033-8.034h-20" stroke="#681D24"/>
  </svg>
</template>

<script>
export default {
  name: 'NextSVG'
}
</script>