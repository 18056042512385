<template>
  <div class="c-about" id="about">
    <div class="c-about__content c-section__content" data-section-content>
      <SectionLabel title="About" background="gum" text="wine" class="c-about__section-label" data-animate/>
      <div class="c-about__section-container">
        <div class="c-about__content-container" data-animate>
          <h1 class="c-about__headline">working together toward an uncommon vision</h1>
          <div class="c-about__accordions-wrapper">
            <div class="c-about__accordions">
              <Accordion title="Alliance HP">
                <div class="c-about__accordion-content">In 2014, Alliance HP acquired a warehouse and a parking lot between Northern Liberties and Old City. They called that project SoNo which stands for Southern Northern Liberties. SoNo is now home to Yards Brewing Company, Target, Philadelphia City Archives, two Mural Arts installations, the City’s largest private rooftop solar array and THE BLOCK. THE BLOCK is the result of a partnership between Alliance HP, Callahan Ward and ISA.</div>
                <a class="c-about__link o-link__wipe o-link__wipe--alt" href="https://alliancehp.com">alliancehp.com <LinkArrowSVG class="c-about__link-arrow" /></a>
              </Accordion>
              <Accordion title="ISA Architects">
                <div class="c-about__accordion-content"><a class="o-link" href="http://www.is-architects.com/">ISA</a> is an award-winning architecture and design studio based in Philadelphia’s Kensington neighborhood. ISA has worked with Callahan Ward on many residential projects, including the widely published <a class="o-link" href="https://www.architectmagazine.com/project-gallery/xs-house_1_o">XS House</a> and <a class="o-link" href="https://www.dezeen.com/2019/01/30/tiny-tower-house-isa-philadelphia/">Tiny Tower</a> projects. For this project, the firm was inspired by the challenge of creating pedestrian-friendly urban housing in the context of a Target parking lot.</div>
              </Accordion>
              <Accordion title="Callahan Ward">
                <div class="c-about__accordion-content">We LOVE Philadelphia! Callahan Ward is a Philadelphia-based developer/builder focused on ground-up residential projects of all shapes and sizes throughout the City of Philadelphia and beyond. We strive to design and build noteworthy buildings with the hope of bettering the surrounding community and the lives of those who live in it.  Callahan Ward met Alliance over scrambled eggs at Honey’s Sit and Eat back in 2014. Thanks to Blaise for the intro!</div>
                <a class="c-about__link o-link__wipe o-link__wipe--alt" href="https://callahanward.com">callahanward.com <LinkArrowSVG class="c-about__link-arrow" /></a>
              </Accordion>
            </div>
          </div>
        </div>
        <div class="c-about__image-wrapper">
          <img class="c-about__image" src="/images/the-block-building-yard.jpg" alt="Back building facade" data-animate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionLabel from './SectionLabel.vue';
import Accordion from './Accordion.vue';
import LinkArrowSVG from './LinkArrowSVG.vue';

export default {
  name: 'About',
  components: {
    SectionLabel,
    Accordion,
    LinkArrowSVG
  }
}
</script>

<style lang="scss" scoped>
  $about-margins: 0 $margin-extra-small-mobile;

  .c-about {
    position: relative;
    padding-top: 8.2rem;
    margin-bottom: 8.3rem;
  }

  .c-about__section-label {
    margin: $about-margins 6.5rem;

    @include media-breakpoint-up(medium) {
      margin: 0 $margin-large 5.3rem;
    }
  }

  .c-about__headline {
    @extend %o-title;
    @extend %o-title--large;
    color: color(text-alt);
    margin: $about-margins 3.6rem;
    @include media-breakpoint-up(medium) {
      flex: 60%;
      margin: 0;
    }
  }

  .c-about__accordions {
    margin: $about-margins 6.7rem;
  }

  .c-about__accordions-wrapper {
    @include media-breakpoint-up(medium) {
      position: relative;
      flex: 40%;
      margin: 0 0 0 6.7rem;
    }
  }

  .c-about__accordion-content {
    @extend %o-body-text;
    margin-bottom: 2.8rem;
    color: color(text-alt);
  }

  .c-about__image-wrapper {
    margin-left: 2rem;
    margin-right: 2rem;

    @include media-breakpoint-up(medium) {
      margin: 9.5rem 0 0;
      width: 50%;
    }
  }

  .c-about__link {
    @extend %o-body-text;
    @extend %o-body-text--large;

    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2.8rem;
    color: color(text-alt);
    text-decoration: none;
    padding: 0.3rem 0.9rem;

    &:hover {
      .c-about__link-arrow {
        stroke: color(text-alt);
      }
    }
  }

  .c-about__link-arrow {
    width: 2rem;
    height: 2rem;
    margin-left: 2rem;
    stroke: color(link-arrow);
    transition: all 355ms ease-in;
  }

  .c-about__content-container {
    @include media-breakpoint-up(medium) {
      display: flex;
      flex-direction: row;
      margin: 0 $margin-large;
    }
  }
</style>