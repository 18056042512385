<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97 97">
    <path fill="#681D24" d="M0 0h97v97H0z"/>
    <g clip-path="url(#clip0)" stroke="#EE592E" stroke-width=".465" stroke-miterlimit="10">
      <path d="M13.019 11.512v39.605h8.284l2.12-2.12 2.986-2.988V11.512h-13.39zm4.335 35.268V15.752h4.816v28.33l-2.697 2.698h-2.12z" fill="#EE592E"/>
      <path d="M21.303 46.78H13.02v39.605h13.39V51.984l-5.106-5.204zm.867 35.269h-4.816V51.02h2.119l2.697 2.698v28.33zM84.782 86.289H31.128V11.415h4.335v70.537h49.32v4.337z" fill="#EE592E"/>
      <path d="M40.183 11.512v65.333h13.39V11.512h-13.39zm9.055 60.997h-4.816V15.752h4.816v56.757zM62.724 15.752v56.757h22.059v4.336H58.389V11.512h26.393v4.24H62.724z" fill="#EE592E"/>
      <path d="M71.875 20.955H67.54v46.447h4.335V20.955z" fill="#EE592E"/>
      <path d="M83.53 67.402h-4.335l-3.949-21.585h-5.683V41.48h9.247l.385 2.12 4.335 23.802z" fill="#EE592E"/>
      <path d="M65.999 72.509h18.495-18.495z"/>
      <path d="M83.338 20.955L79.195 43.6l-.385 2.217h-9.247V41.48h5.683l3.757-20.525h4.335z" fill="#EE592E"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" transform="translate(12.826 11.223)" d="M0 0h72.149v75.355H0z"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'BlockSVG'
}
</script>