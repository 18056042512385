<template>
  <div class="c-building" id="the-building" data-animate>
    <SectionLabel title="the building" background="wine" text="gum" />
    <p class="c-building__text">49 residences, and a 7,200 square foot private roofdeck with a dog park, fire pits, and a gorgeous view of the city.</p>
    <div class="c-building__logo">
      <LogoSmall />
    </div>
  </div>
</template>

<script>
import SectionLabel from './SectionLabel.vue';
import LogoSmall from './LogoSmall.vue';

export default {
  name: 'Building',
  components: {
    SectionLabel,
    LogoSmall
  }
}
</script>

<style lang="scss" scoped>
  .c-building {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6.2rem $margin-extra-small-mobile 10.3rem;
    background-color: color(bg-purple);
    scroll-behavior: smooth;

    @include media-breakpoint-up(medium) {
      padding: 14.4rem $margin-medium 10.7rem;
    }
  }

  .c-building__text {
    @extend %o-title;
    @extend %o-title--large;
    text-align: center;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-transform: none;
    line-height: 9rem;
  }

  .c-building__logo {
    position: absolute;
    bottom: -2rem;
    svg {
      fill: color(bg-orange);
    }

    @include media-breakpoint-up(medium) {
      right: 20.5rem;
      bottom: -9.7rem;
    }
  }
</style>