<template>
  <div class="c-social-icons">
    <a class="c-social-icons__icon-wrapper" href="https://www.instagram.com/theblockatsono/"><Instagram class="c-social-icons__icon"/></a>
  </div>
</template>

<script>
import Instagram from './icons/Instagram.vue';

export default {
  name: 'SocialIcons',
  components: {
    Instagram
  }
}
</script>

<style lang="scss" scoped>
  $divider: 1px solid color(divider-light);

  .c-social-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 4rem 6.6rem;
    margin-bottom: 2.1rem;
    border-top: $divider;
    border-bottom: $divider;

    @include media-breakpoint-up(medium) {
      order: 2;
      margin: 0;
      padding: 0 13rem;
      border: none;
    }
  }

  .c-social-icons__icon {
    position: relative;
    height: 2rem;
    width: auto;
    transition: all 355ms ease-in;
  }

  .c-social-icons__icon-wrapper {
    border-bottom: 1px solid transparent;
    transition: all 355ms ease-in;

    &:hover {
      border-bottom: 1px solid color(bg-plinth);
    }

    &:hover .c-social-icons__icon {
      fill: color(bg-plinth);
      transform: translateY(-0.8rem);
    }
  }
</style>